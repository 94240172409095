import { PERMISSIONS_PATH } from '@/path';
import { Link, Typography, TypographyProps } from '@mui/material';
import { PrivacyPolicyLink, TermsOfUseLink } from '@verifime/components';

export const NO_SELECTED_PERMISSION = 'Please select an organisation to grant the permission';
export const NO_ORGANISATION = 'There is no any organisation';
export const NOT_SEARCHED = 'Please search to find organisation';
export const NO_ACTIVE_PERMISSIONS = 'No active permissions';
export const FAILED_TO_LOAD_IDENTITIES = 'Failed to load identities';
export const FORM_VALIDATION_FAILED =
  'There are validation errors in the form, please correct the data.';

// notification messages
export const IDENTITY_SUBMISSION_SUCCESS =
  'Your identity record has been submitted for verification.';
export const WHOLESALE_CERTIFICATE_SUBMISSION_SUCCESS =
  'Your Wholesale Certificate has been submitted for verification.';
export const PERMISSION_GRANTED_SUCCESS = 'Your permission record has been submitted.';
export const PERMISSION_REVOKED_SUCCESS =
  'Your permission cancellation request has been submitted.';

export const ACTIVE_PERMISSION_CANCEL_CONFIRM = 'Are you sure you wish to cancel this permission?';

export const DEFAULT_CONSENT =
  'I consent to my personal information provided above being checked with the documents issues or the official record holder, credit bureaus, mobile provider or other third-party systems via an identity service provider, for the purposes of identity verification and AML/CFT compliance. I consent for this information and results being stored for audit and compliance purposes.';

export const WIZARD_STEP1_NO_NEED_DISCARD = 'There is nothing needs to be discared.';
export const WIZARD_STEP1_DISCARD_PROMPT_TITLE = 'Discard draft';
export const WIZARD_STEP1_DISCARD_PROMPT_TEXT =
  'Are you sure you want to discard all the details you have entered for this identity?';
export const WIZARD_STEP1_DISCARD_BUTTON_CANCEL = 'Cancel';
export const WIZARD_STEP1_DISCARD_BUTTON_CONFIRM = 'Discard';
export const WIZARD_STEP1_DISCARD_SUCCESS = 'Discarded';

export const WIZARD_ORGANISATION_STEP5_SUCCESS_MESSAGES = {
  header: 'What happens next?',
  body: [
    'We will email you when the verification is complete.',
    'We will also inform all other individuals you have added as part of this organisation. They can join VerifiMe and keep track of how their details are used. They can also add any additional documents directly to VerifiMe.',
    <>
      Once verified, you can share your verified status with your service provider by granting them
      a <Link href={PERMISSIONS_PATH}>Permission</Link>.
    </>,
  ],
};

export const WIZARD_INDIVIDUAL_STEP5_SUCCESS_MESSAGES = {
  header: 'What happens next?',
  body: [
    'We will review your details to complete the verification process. If more information is needed we will contact you directly.',
    'We will notify you when the verification is complete.',
    <>
      Once verified, you can share your verified status with your service provider by granting them
      a <Link href={PERMISSIONS_PATH}>Permission</Link>.
    </>,
  ],
};

export const WIZARD_STEPS_TERMS_AND_CONDITIONS = (
  {
    variant = 'body1',
  }: {
    variant?: TypographyProps['variant'];
  } = { variant: 'body1' },
) => (
  <>
    <Typography variant={variant}>
      By clicking submit, I agree that my personal and contact information may be used for
      verification, including by being checked with relevant Government agencies and other,
      commercially operated, third-party systems for identity verification.
    </Typography>
    <Typography variant={variant}>
      I acknowledge that VerifiMe will store the details and results of this verification.
    </Typography>
    <Typography variant={variant}>
      When adding individuals to an organisation, that person will be notified by email and given
      the option to manage their identity status through VerifiMe directly.
    </Typography>
    <Typography variant={variant}>
      I have read and agree to be bound by VerifiMe&apos;s <TermsOfUseLink /> and{' '}
      <PrivacyPolicyLink />.
    </Typography>
  </>
);

export const WIZARD_ORGANISATION_STEPS_TERMS_AND_CONDITIONS = (
  <>
    <Typography variant="body1">
      By clicking submit, I agree that my personal and contact information may be used for
      verification, including by being checked with relevant Government agencies and other,
      commercially operated, third-party systems for identity verification.
    </Typography>
    <Typography variant="body1">
      I acknowledge that VerifiMe will store the details and results of this verification.
    </Typography>
    <Typography variant="body1">
      I confirm the uploaded documents are current, and no subsequent versions or amendments exist.
    </Typography>
    <Typography variant="body1">
      When adding individuals to an organisation, that person will be notified by email and given
      the option to manage their identity status through VerifiMe directly.
    </Typography>
    <Typography variant="body1">
      I have read and agree to be bound by VerifiMe&apos;s <TermsOfUseLink /> and{' '}
      <PrivacyPolicyLink />.
    </Typography>
  </>
);

export const UPLOAD_TRUST_DEED = {
  title: 'Upload Trust Deed',
  subtitle:
    'Add any documents that may be needed to verify this organisation. eg Trust Deed, Change of Trustee etc.',
};

export const AT_LEAST_ONE_TRUSTEE = 'At least one trustee must be specified';
export const AT_LEAST_ONE_DIRECTOR = 'At least one director must be specified';
export const AT_LEAST_ONE_TRUST_DEED = 'At least one trust deed must be specified';
export const AT_LEAST_ONE_PARTNERSHIP_AGREEMENT =
  'At least one partnership agreement must be specified';
export const AT_LEAST_TWO_PARTNERS = 'At least two partners must be specified';

export const LANDING_TITLE = 'Stop sharing your IDs over and over';
export const LANDING_DESCRIPTION =
  'Build a VerifiMe identity once and only share your verified status with the service provider next time.';
export const META_DESCRIPTION =
  'VerifiMe is an identity verification platform for individuals, trusts and businesses.';
