import { NAV_ITEMS, NAV_ITEM_HOME } from '@/path';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { usePasswordless } from '@verifime/cognito-passwordless-auth';
import { SideNav, TopBarV2 as TopBar } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

export default function Layout({ children }: PropsWithChildren) {
  const theme = useTheme();

  const router = useRouter();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { signOut, tokensParsed } = usePasswordless();
  const email = tokensParsed?.idToken?.email || '';

  const signOutAndRedirect = () => {
    signOut().signedOut.then(() => {
      router.push('/');
    });
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!isDesktop && (
        <TopBar
          navItems={NAV_ITEMS}
          signOut={signOutAndRedirect}
          homeHref={NAV_ITEM_HOME.path}
          email={{ value: email, fullwidth: true }}
        />
      )}
      <div style={{ display: 'flex', flex: 'auto' }}>
        {isDesktop && (
          <SideNav
            navItems={NAV_ITEMS}
            doSignOut={signOutAndRedirect}
            homeNavItem={NAV_ITEM_HOME}
            email={{ value: email }}
          />
        )}
        <Box
          sx={{
            minWidth: tokens.sizeXs,
            padding: { xs: tokens.spacingXs },
            flex: 1,
            paddingBottom: { xs: tokens.spacing4xl, sm: tokens.spacingXs },
          }}
        >
          {children}
        </Box>
      </div>
    </div>
  );
}
