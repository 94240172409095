export enum FormStatus {
  UNKNOWN,
  UNFILLED,
  FILLED,
  DOING_UPDATE,
}

export const LONG_NAME_LENGTH = 30;

export const APPLICATION_NAME = 'VerifiMe Customer Portal';
