export default function useAPIIdentities() {
  const {
    data,
    isLoading,
    error,
    mutate,
  }: {
    data: defs.person.CustomerListResponse;
    isLoading: boolean;
    error: string;
    mutate: (data?: any, shouldRevalidate?: boolean | undefined) => Promise<any>;
  } = API.person.customerResource.getCustomerMe.useRequest(
    {
      recursive: false,
    },
    {
      dedupingInterval: 0,
    },
  );

  return {
    identities: data?.customerList || [],
    isLoadingIdentities: isLoading,
    loadIdentitiesError: error,
    mutateIdentities: mutate,
  };
}
