import { default as HomeIcon } from '@mui/icons-material/HomeRounded';
import { default as MyIdentityIcon } from '@mui/icons-material/ContactsRounded';
import { default as MyPermissionsIcon } from '@mui/icons-material/SecurityRounded';
import { default as MyTasksIcon } from '@mui/icons-material/TaskAltRounded';
import { default as SettingsIcon } from '@mui/icons-material/SettingsRounded';
import { TNavItem } from '@verifime/components';

export const BASE_PATH = '/dashboard';
export const ADMIN_PATH = '/admin';
export const PERMISSIONS_PATH = `${BASE_PATH}/permissions`;

export const WIZARD_BASE_PATH = `${BASE_PATH}/wizard`;

export const WIZARD_PATH_SUFFIX_INDIVIDUAL = 'individual';
export const WIZARD_PATH_SUFFIX_SMSF = 'smsf';
export const WIZARD_PATH_SUFFIX_REGULATED_TRUST = 'trust-regulated';
export const WIZARD_PATH_SUFFIX_UNREGULATED_TRUST = 'trust-unregulated';
export const WIZARD_PATH_SUFFIX_PRIVATE_COMPANY = 'private-company';
export const WIZARD_PATH_SUFFIX_PUBLIC_COMPANY = 'public-company';
export const WIZARD_PATH_SUFFIX_SOLE_TRADER = 'sole-trader';
export const WIZARD_PATH_SUFFIX_PARTNERSHIP = 'partnership';

export const WIZARD_PATH_INDIVIDUAL = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_INDIVIDUAL}`;
export const WIZARD_PATH_SMSF = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_SMSF}`;
export const WIZARD_PATH_REGULATED_TRUST = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_REGULATED_TRUST}`;
export const WIZARD_PATH_UNREGULATED_TRUST = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_UNREGULATED_TRUST}`;
export const WIZARD_PATH_PRIVATE_COMPANY = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_PRIVATE_COMPANY}`;
export const WIZARD_PATH_PUBLIC_COMPANY = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_PUBLIC_COMPANY}`;
export const WIZARD_PATH_SOLE_TRADER = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_SOLE_TRADER}`;
export const WIZARD_PATH_PARTNERSHIP = `${WIZARD_BASE_PATH}/${WIZARD_PATH_SUFFIX_PARTNERSHIP}`;

export enum Role {
  Customer = 'customer',
  Admin = 'admin',
}

export const NAV_ITEM_HOME: TNavItem = {
  path: `${BASE_PATH}/identity`,
  label: 'Home',
  icon: HomeIcon,
  role: Role.Customer,
};

export const NAV_ITEM_IDENTITY: TNavItem = {
  path: `${BASE_PATH}/identity`,
  label: 'Identity',
  icon: MyIdentityIcon,
  role: Role.Customer,
};

export const NAV_ITEM_PERMISSIONS: TNavItem = {
  path: PERMISSIONS_PATH,
  label: 'Permissions',
  icon: MyPermissionsIcon,
  role: Role.Customer,
};
export const NAV_ITEM_TASKS: TNavItem = {
  path: `${BASE_PATH}/tasks`,
  label: 'Tasks',
  icon: MyTasksIcon,
  role: Role.Customer,
};
export const NAV_ITEM_SETTINGS: TNavItem = {
  path: `${BASE_PATH}/settings`,
  label: 'Settings',
  icon: SettingsIcon,
  role: Role.Customer,
};

const NAV_ITEM_DIV: TNavItem = {
  label: '',
  path: '#',
  isDivider: true,
  role: Role.Customer,
};

export const NAV_ITEMS: TNavItem[] = [
  NAV_ITEM_HOME,
  NAV_ITEM_PERMISSIONS,
  // Hide tasks for the time being
  // NAV_ITEM_TASKS,
  NAV_ITEM_DIV,
  NAV_ITEM_SETTINGS,
];
