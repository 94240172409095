import { TPermissionRequestSummaryList } from '../types';

export default function useAPIPermissionRequests() {
  const { data, isLoading, mutate, error } =
    API.person.permissionRequestResource.getRequestMe.useRequest(
      {},
      {
        dedupingInterval: 0,
      },
    );

  const uncompletedPermissionRequests = (data as TPermissionRequestSummaryList)?.filter(
    (request) => request.completedDateTime == null,
  );

  return {
    permissionRequests: uncompletedPermissionRequests || [],
    isLoadingPermissionRequests: isLoading,
    mutatePermissionRequests: mutate,
    loadPermissionRequestsError: error,
  };
}
