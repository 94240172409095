import { TPermission } from '../types';

export default function useAPIPermissions(customerId: string) {
  const {
    data,
    isLoading,
    mutate,
    error,
  }: {
    data: TPermission[];
    error: any;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
    isLoading: boolean;
  } = API.person.permissionResource.getPermission.useRequest({
    customerId,
  });

  return {
    permissions: data || [],
    isLoadingPermissions: isLoading,
    mutatePermissions: mutate,
    loadPermissionsError: error,
  };
}
